import { SvgIcon } from '@mui/material';
import { DefaultComponentProps, OverridableTypeMap } from '@mui/material/OverridableComponent';

export interface ColorProps extends DefaultComponentProps<OverridableTypeMap> {
  color: string;
}

const LogoIcon = (props: DefaultComponentProps<OverridableTypeMap>) => {
  return (
    <SvgIcon viewBox="0 0 1157.000000 549.000000" preserveAspectRatio="xMidYMid meet" {...props} sx={{ height: "40px", width: "auto", ...props.sx }}>
      <g transform="translate(0.000000,549.000000) scale(0.100000,-0.100000)"
        fill={props.color} stroke="#FFFFFF">
        <path d="M11080 5420 c0 -5 5 -10 10 -10 6 0 46 -34 90 -76 106 -101 150 -185
        150 -284 0 -70 -24 -166 -44 -173 -28 -11 -1734 -696 -2100 -844 -224 -90
        -410 -163 -412 -161 -2 2 8 38 23 79 33 95 37 184 8 206 -58 43 -231 -32 -524
        -228 -100 -66 -181 -123 -181 -127 0 -4 33 -32 72 -62 40 -30 139 -106 220
        -167 81 -62 150 -113 152 -113 5 0 332 156 654 311 78 38 146 69 149 69 4 0
        -18 -26 -49 -57 -105 -108 -164 -228 -186 -375 -10 -65 -3 -73 20 -25 19 39
        85 116 153 178 288 264 965 618 1975 1032 l205 84 26 94 c28 98 28 119 2 209
        -48 171 -160 311 -328 409 -73 43 -85 47 -85 31z"/>
        <path d="M609 4417 c-2 -3 -69 -7 -149 -11 -190 -8 -312 -17 -329 -22 -9 -3 1
        -19 34 -50 l48 -46 121 -3 c67 -1 160 -3 209 -3 l87 -2 0 -77 c1 -209 61 -410
        199 -658 71 -127 165 -262 349 -501 31 -41 32 -44 18 -67 -26 -38 -54 -130
        -61 -198 -15 -141 43 -298 146 -398 75 -74 152 -114 257 -137 111 -24 204 -10
        318 46 134 65 235 195 264 337 15 69 8 200 -14 268 -25 80 -93 172 -171 232
        -90 68 -158 94 -263 101 -103 6 -188 -11 -267 -51 -33 -18 -63 -33 -67 -35
        -24 -11 15 204 62 347 69 213 194 387 351 493 l54 36 65 -35 c105 -58 219
        -142 309 -228 59 -57 81 -73 75 -55 -5 14 -9 57 -9 95 1 58 5 77 26 110 28 43
        102 115 139 135 21 11 20 13 -41 54 -90 60 -180 107 -279 145 -47 18 -95 36
        -106 41 -118 46 -416 103 -653 124 -133 12 -711 22 -722 13z m608 -207 c179
        -36 208 -44 208 -56 0 -5 -19 -48 -42 -95 -91 -187 -133 -408 -142 -742 l-6
        -228 -53 89 c-152 257 -218 447 -229 653 -6 129 4 207 44 332 22 67 29 77 49
        77 13 0 90 -13 171 -30z"/>
        <path d="M4440 3783 c0 -5 -13 -82 -30 -173 -16 -91 -30 -171 -30 -177 0 -10
        51 -13 223 -13 l224 0 27 158 c16 86 30 169 33 185 l6 27 -227 0 c-124 0 -226
        -3 -226 -7z"/>
        <path d="M2860 2703 c-95 -544 -175 -996 -178 -1005 -4 -17 29 -18 601 -18
        l606 0 6 33 c3 17 18 102 34 187 l27 155 -378 3 c-223 1 -378 6 -378 12 0 5
        63 366 140 802 77 436 140 798 140 805 0 10 -50 13 -223 13 l-223 0 -174 -987z"/>
        <path d="M7028 3235 c-161 -31 -285 -94 -406 -209 -174 -165 -283 -408 -312
        -695 -35 -350 108 -579 417 -663 66 -18 107 -21 258 -22 164 0 190 2 290 27
        61 15 146 42 190 60 l80 33 32 179 c18 99 32 188 33 198 0 26 -36 21 -85 -13
        -129 -89 -307 -150 -437 -150 -120 0 -234 37 -285 92 -48 51 -83 180 -68 248
        l7 30 463 0 462 0 17 93 c29 157 39 259 32 347 -16 232 -115 370 -309 432 -83
        26 -274 33 -379 13z m154 -295 c79 -22 128 -128 113 -246 l-6 -54 -251 0 -250
        0 7 28 c11 47 53 132 85 172 71 89 194 130 302 100z"/>
        <path d="M4346 3183 c-3 -10 -62 -342 -131 -738 -70 -396 -129 -730 -132 -742
        l-5 -23 215 0 215 0 6 28 c3 15 62 347 131 737 68 391 127 720 130 733 l5 22
        -214 0 c-196 0 -215 -2 -220 -17z"/>
        <path d="M4951 3183 c0 -10 48 -351 108 -758 l107 -740 232 -3 232 -2 380 760
        380 760 -222 0 -223 -1 -219 -489 c-121 -269 -221 -488 -224 -486 -3 4 -102
        938 -102 969 0 4 -101 7 -225 7 -208 0 -225 -1 -224 -17z"/>
        <path d="M9170 2793 c0 -119 -50 -326 -111 -461 -123 -272 -365 -532 -710
        -760 -552 -365 -1248 -706 -1951 -956 -574 -204 -1070 -297 -1588 -298 -621
        -1 -1022 99 -1340 336 -75 56 -205 196 -268 289 -37 54 -68 93 -70 87 -7 -19
        94 -216 165 -325 83 -127 262 -308 386 -389 303 -200 611 -280 1077 -279 375
        0 717 47 1200 164 1107 268 2029 721 2690 1322 316 286 513 603 560 899 21
        128 -1 418 -31 418 -5 0 -9 -21 -9 -47z"/>
        <path d="M11080 5420 c0 -5 5 -10 10 -10 6 0 46 -34 90 -76 106 -101 150 -185
        150 -284 0 -70 -24 -166 -44 -173 -28 -11 -1734 -696 -2100 -844 -224 -90
        -410 -163 -412 -161 -2 2 8 38 23 79 33 95 37 184 8 206 -58 43 -231 -32 -524
        -228 -100 -66 -181 -123 -181 -127 0 -4 33 -32 72 -62 40 -30 139 -106 220
        -167 81 -62 150 -113 152 -113 5 0 332 156 654 311 78 38 146 69 149 69 4 0
        -18 -26 -49 -57 -105 -108 -164 -228 -186 -375 -10 -65 -3 -73 20 -25 19 39
        85 116 153 178 288 264 965 618 1975 1032 l205 84 26 94 c28 98 28 119 2 209
        -48 171 -160 311 -328 409 -73 43 -85 47 -85 31z"/>
        <path d="M609 4417 c-2 -3 -69 -7 -149 -11 -190 -8 -312 -17 -329 -22 -9 -3 1
        -19 34 -50 l48 -46 121 -3 c67 -1 160 -3 209 -3 l87 -2 0 -77 c1 -209 61 -410
        199 -658 71 -127 165 -262 349 -501 31 -41 32 -44 18 -67 -26 -38 -54 -130
        -61 -198 -15 -141 43 -298 146 -398 75 -74 152 -114 257 -137 111 -24 204 -10
        318 46 134 65 235 195 264 337 15 69 8 200 -14 268 -25 80 -93 172 -171 232
        -90 68 -158 94 -263 101 -103 6 -188 -11 -267 -51 -33 -18 -63 -33 -67 -35
        -24 -11 15 204 62 347 69 213 194 387 351 493 l54 36 65 -35 c105 -58 219
        -142 309 -228 59 -57 81 -73 75 -55 -5 14 -9 57 -9 95 1 58 5 77 26 110 28 43
        102 115 139 135 21 11 20 13 -41 54 -90 60 -180 107 -279 145 -47 18 -95 36
        -106 41 -118 46 -416 103 -653 124 -133 12 -711 22 -722 13z m608 -207 c179
        -36 208 -44 208 -56 0 -5 -19 -48 -42 -95 -91 -187 -133 -408 -142 -742 l-6
        -228 -53 89 c-152 257 -218 447 -229 653 -6 129 4 207 44 332 22 67 29 77 49
        77 13 0 90 -13 171 -30z"/>
      </g>

    </SvgIcon >
  );
}

export { LogoIcon };
/*
const LogoIcon = (props: DefaultComponentProps<OverridableTypeMap>) => {
  return (
    <SvgIcon viewBox='0 0 1195.000000 554.000000' {...props} sx={{ height: "50px", width: "auto", ...props.sx }}>
      <g transform="translate(0.000000,554.000000) scale(0.100000,-0.100000)"
        fill={props.color} stroke="none">
        <path d="M10050 5420 c0 -5 5 -10 10 -10 6 0 46 -34 90 -76 106 -101 150 -185
        150 -284 0 -70 -24 -166 -44 -173 -28 -11 -1734 -696 -2100 -844 -224 -90
        -410 -163 -412 -161 -2 2 8 38 23 79 33 95 37 184 8 206 -58 43 -231 -32 -524
        -228 -100 -66 -181 -123 -181 -127 0 -4 33 -32 72 -62 40 -30 139 -106 220
        -167 81 -62 150 -113 152 -113 5 0 332 156 654 311 78 38 146 69 149 69 4 0
        -18 -26 -49 -57 -105 -108 -164 -228 -186 -375 -10 -65 -3 -73 20 -25 19 39
        85 116 153 178 288 264 965 618 1975 1032 l205 84 26 94 c28 98 28 119 2 209
        -48 171 -160 311 -328 409 -73 43 -85 47 -85 31z"/>
        <path d="M3410 3783 c0 -5 -13 -82 -30 -173 -16 -91 -30 -171 -30 -177 0 -10
        51 -13 223 -13 l224 0 27 158 c16 86 30 169 33 185 l6 27 -227 0 c-124 0 -226
        -3 -226 -7z"/>
        <path d="M1830 2703 c-95 -544 -175 -996 -178 -1005 -4 -17 29 -18 601 -18
        l606 0 6 33 c3 17 18 102 34 187 l27 155 -378 3 c-223 1 -378 6 -378 12 0 5
        63 366 140 802 77 436 140 798 140 805 0 10 -50 13 -223 13 l-223 0 -174 -987z"/>
        <path d="M5998 3235 c-161 -31 -285 -94 -406 -209 -174 -165 -283 -408 -312
        -695 -35 -350 108 -579 417 -663 66 -18 107 -21 258 -22 164 0 190 2 290 27
        61 15 146 42 190 60 l80 33 32 179 c18 99 32 188 33 198 0 26 -36 21 -85 -13
        -129 -89 -307 -150 -437 -150 -120 0 -234 37 -285 92 -48 51 -83 180 -68 248
        l7 30 463 0 462 0 17 93 c29 157 39 259 32 347 -16 232 -115 370 -309 432 -83
        26 -274 33 -379 13z m154 -295 c79 -22 128 -128 113 -246 l-6 -54 -251 0 -250
        0 7 28 c11 47 53 132 85 172 71 89 194 130 302 100z"/>
        <path d="M475 3216 c-244 -63 -410 -319 -365 -566 18 -99 71 -201 141 -269 75
        -74 152 -114 257 -137 111 -24 204 -10 318 46 134 65 235 195 264 337 15 69 8
        200 -14 268 -38 121 -170 257 -296 307 -68 26 -229 34 -305 14z"/>
        <path d="M3316 3183 c-3 -10 -62 -342 -131 -738 -70 -396 -129 -730 -132 -742
        l-5 -23 215 0 215 0 6 28 c3 15 62 347 131 737 68 391 127 720 130 733 l5 22
        -214 0 c-196 0 -215 -2 -220 -17z"/>
        <path d="M3921 3183 c0 -10 48 -351 108 -758 l107 -740 232 -3 232 -2 380 760
        380 760 -222 0 -223 -1 -219 -489 c-121 -269 -221 -488 -224 -486 -3 4 -102
        938 -102 969 0 4 -101 7 -225 7 -208 0 -225 -1 -224 -17z"/>
        <path d="M8140 2793 c0 -119 -50 -326 -111 -461 -123 -272 -365 -532 -710
        -760 -552 -365 -1248 -706 -1951 -956 -574 -204 -1070 -297 -1588 -298 -621
        -1 -1022 99 -1340 336 -75 56 -205 196 -268 289 -37 54 -68 93 -70 87 -7 -19
        94 -216 165 -325 83 -127 262 -308 386 -389 303 -200 611 -280 1077 -279 375
        0 717 47 1200 164 1107 268 2029 721 2690 1322 316 286 513 603 560 899 21
        128 -1 418 -31 418 -5 0 -9 -21 -9 -47z"/>
      </g>

    </SvgIcon >
  );
}

const LogoIcon2 = (props: DefaultComponentProps<OverridableTypeMap>) => {
  return (
    <SvgIcon viewBox="0 0 1052.000000 554.000000"{...props} sx={{ height: "50px", width: "auto", ...props.sx }}>
      <g transform="translate(0.000000,554.000000) scale(0.100000,-0.100000)"
        fill={props.color} stroke="none">
        <path d="M10050 5420 c0 -5 5 -10 10 -10 6 0 46 -34 90 -76 106 -101 150 -185
      150 -284 0 -70 -24 -166 -44 -173 -28 -11 -1734 -696 -2100 -844 -224 -90
      -410 -163 -412 -161 -2 2 8 38 23 79 33 95 37 184 8 206 -58 43 -231 -32 -524
      -228 -100 -66 -181 -123 -181 -127 0 -4 33 -32 72 -62 40 -30 139 -106 220
      -167 81 -62 150 -113 152 -113 5 0 332 156 654 311 78 38 146 69 149 69 4 0
      -18 -26 -49 -57 -105 -108 -164 -228 -186 -375 -10 -65 -3 -73 20 -25 19 39
      85 116 153 178 288 264 965 618 1975 1032 l205 84 26 94 c28 98 28 119 2 209
      -48 171 -160 311 -328 409 -73 43 -85 47 -85 31z"/>
        <path d="M3410 3783 c0 -5 -13 -82 -30 -173 -16 -91 -30 -171 -30 -177 0 -10
      51 -13 223 -13 l224 0 27 158 c16 86 30 169 33 185 l6 27 -227 0 c-124 0 -226
      -3 -226 -7z"/>
        <path d="M1830 2703 c-95 -544 -175 -996 -178 -1005 -4 -17 29 -18 601 -18
      l606 0 6 33 c3 17 18 102 34 187 l27 155 -378 3 c-223 1 -378 6 -378 12 0 5
      63 366 140 802 77 436 140 798 140 805 0 10 -50 13 -223 13 l-223 0 -174 -987z"/>
        <path d="M5998 3235 c-161 -31 -285 -94 -406 -209 -174 -165 -283 -408 -312
      -695 -35 -350 108 -579 417 -663 66 -18 107 -21 258 -22 164 0 190 2 290 27
      61 15 146 42 190 60 l80 33 32 179 c18 99 32 188 33 198 0 26 -36 21 -85 -13
      -129 -89 -307 -150 -437 -150 -120 0 -234 37 -285 92 -48 51 -83 180 -68 248
      l7 30 463 0 462 0 17 93 c29 157 39 259 32 347 -16 232 -115 370 -309 432 -83
      26 -274 33 -379 13z m154 -295 c79 -22 128 -128 113 -246 l-6 -54 -251 0 -250
      0 7 28 c11 47 53 132 85 172 71 89 194 130 302 100z"/>
        <path d="M475 3216 c-244 -63 -410 -319 -365 -566 18 -99 71 -201 141 -269 75
      -74 152 -114 257 -137 111 -24 204 -10 318 46 134 65 235 195 264 337 15 69 8
      200 -14 268 -38 121 -170 257 -296 307 -68 26 -229 34 -305 14z"/>
        <path d="M3316 3183 c-3 -10 -62 -342 -131 -738 -70 -396 -129 -730 -132 -742
      l-5 -23 215 0 215 0 6 28 c3 15 62 347 131 737 68 391 127 720 130 733 l5 22
      -214 0 c-196 0 -215 -2 -220 -17z"/>
        <path d="M3921 3183 c0 -10 48 -351 108 -758 l107 -740 232 -3 232 -2 380 760
      380 760 -222 0 -223 -1 -219 -489 c-121 -269 -221 -488 -224 -486 -3 4 -102
      938 -102 969 0 4 -101 7 -225 7 -208 0 -225 -1 -224 -17z"/>
        <path d="M8140 2793 c0 -119 -50 -326 -111 -461 -123 -272 -365 -532 -710
      -760 -552 -365 -1248 -706 -1951 -956 -574 -204 -1070 -297 -1588 -298 -621
      -1 -1022 99 -1340 336 -75 56 -205 196 -268 289 -37 54 -68 93 -70 87 -7 -19
      94 -216 165 -325 83 -127 262 -308 386 -389 303 -200 611 -280 1077 -279 375
      0 717 47 1200 164 1107 268 2029 721 2690 1322 316 286 513 603 560 899 21
      128 -1 418 -31 418 -5 0 -9 -21 -9 -47z"/>
      </g>

    </SvgIcon >
  );
}

const LogoIcon3 = (props: DefaultComponentProps<OverridableTypeMap>) => {
  return (
    <SvgIcon viewBox="0 0 1195.000000 800.000000" {...props} sx={{ height: "50px", width: "auto", ...props.sx }}>
      <g transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)"
        fill={props.color} stroke="none">
        <path d="M10745 7861 c11 -5 52 -21 92 -36 135 -50 228 -126 267 -218 20 -49
      33 -167 19 -175 -6 -4 -450 -423 -987 -932 -537 -509 -980 -926 -984 -927 -4
      -2 -8 38 -9 88 -1 72 -6 100 -25 137 -20 40 -28 47 -56 50 -43 4 -86 -23 -156
      -99 -107 -115 -364 -476 -349 -490 8 -7 511 -149 529 -149 9 0 557 584 590
      628 23 31 26 4 4 -38 -57 -112 -75 -287 -43 -423 l16 -72 19 79 c45 193 205
      448 511 812 202 242 376 426 789 836 l390 387 -12 93 c-13 112 -41 165 -139
      262 -81 80 -162 129 -264 161 -75 24 -245 46 -202 26z"/>
        <path d="M442 6720 c-29 -4 -55 -10 -58 -13 -3 -2 11 -43 30 -89 82 -199 117
      -391 97 -542 -18 -136 -54 -223 -135 -333 -25 -35 -42 -63 -37 -63 4 0 53 22
      108 49 304 150 590 220 918 227 332 6 642 -59 985 -209 588 -255 1298 -780
      2053 -1517 76 -74 193 -188 260 -252 108 -104 121 -119 110 -135 -71 -101
      -102 -182 -110 -283 -25 -312 256 -576 565 -531 347 50 535 423 370 733 -39
      73 -147 177 -221 212 -79 37 -148 49 -252 44 -101 -6 -184 -36 -263 -95 -23
      -18 -46 -33 -51 -33 -5 0 -46 41 -92 92 -225 247 -415 444 -749 778 -401 402
      -582 570 -970 904 -167 143 -483 379 -680 508 -367 239 -726 407 -1045 489
      -192 50 -290 61 -540 65 -132 1 -264 -1 -293 -6z"/>
        <path d="M9908 4764 c-3 -3 3 -27 13 -54 61 -161 81 -437 44 -630 -58 -313
      -203 -599 -508 -1003 -262 -346 -465 -577 -831 -942 -294 -294 -502 -483 -721
      -657 -419 -332 -875 -585 -1330 -741 -661 -226 -1128 -205 -1532 69 -51 35
      -93 60 -93 56 0 -13 144 -149 218 -205 294 -225 671 -328 1050 -286 543 59
      1217 395 1982 990 757 588 1328 1246 1674 1929 166 326 240 593 239 860 0 220
      -42 376 -148 546 -49 78 -48 77 -57 68z"/>
        <path d="M6940 4482 c0 -4 -11 -71 -25 -148 -14 -76 -25 -145 -25 -151 0 -10
      32 -13 128 -13 l129 0 22 133 c13 72 25 144 28 160 l6 27 -132 0 c-72 0 -131
      -4 -131 -8z"/>
        <path d="M6115 4388 c-3 -13 -70 -394 -150 -848 -80 -454 -148 -837 -151 -852
      l-6 -28 350 0 350 0 6 28 c3 15 15 81 26 147 12 66 23 128 26 138 5 16 -10 17
      -215 17 -122 0 -221 3 -221 6 0 9 239 1364 245 1392 5 22 5 22 -125 22 -126 0
      -130 -1 -135 -22z"/>
        <path d="M8429 4007 c-202 -57 -363 -298 -434 -650 -50 -249 -43 -428 22 -558
      20 -41 51 -83 76 -104 79 -68 225 -93 352 -60 77 21 184 73 200 99 10 16 65
      296 65 330 0 17 -27 1 -76 -43 -110 -101 -235 -135 -324 -89 -24 13 -52 37
      -62 54 -26 44 -39 130 -32 196 l7 58 263 0 c241 0 263 1 268 18 19 60 46 283
      46 377 0 251 -81 373 -253 381 -40 2 -92 -2 -118 -9z m86 -269 c34 -30 48 -95
      40 -180 l-7 -68 -140 0 -140 0 7 37 c10 56 58 154 91 187 52 51 107 60 149 24z"/>
        <path d="M6860 3973 c0 -9 -217 -1246 -226 -1285 l-6 -28 125 0 125 0 6 28 c9
      44 226 1276 226 1284 0 5 -56 8 -125 8 -69 0 -125 -3 -125 -7z"/>
        <path d="M7216 3898 c7 -119 44 -1112 44 -1181 l0 -57 138 0 137 0 255 651
      c140 357 257 654 258 659 2 6 -49 10 -130 10 l-133 0 -145 -407 -145 -407 -7
      85 c-3 46 -6 229 -7 407 l-1 322 -135 0 -134 0 5 -82z"/>
      </g>

    </SvgIcon >
  );
}




const BackgroundIcon = (props: DefaultComponentProps<OverridableTypeMap>) => {

  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="501.000000pt" height="585.000000pt" viewBox="0 0 501.000000 585.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,585.000000) scale(0.100000,-0.100000)"
        fill={props.color} stroke="none">
        <path d="M0 2925 l0 -2925 2505 0 2505 0 0 2925 0 2925 -2505 0 -2505 0 0 -2925z" />
      </g>
    </svg>
  )
};
*/
